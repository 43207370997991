const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
const { name } = require('store/storages/cookieStorage')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    siteId: '670697ee3ececc0ce3e00aea',
    authMode: ['providers'],
    authOAuthProviders: [
      [
        'WSSOKeycloak',
        [
          {
            realm: 'Axima',
            clientId: 'vi-axima',
            buttonText: 'Connexion Axima',
          },
        ],
      ],
    ],
    enableExperimentalBlocks: false,
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    // disabledComponents: ['wButtonLike', 'wTagLikeCount','wCardsEngagementLabels'],
    metadatas: {
      name: 'Radio Frigo',
      colorPrimary: '#70BD95',
      colorAccent: '#002439',
      supportEmailAddress: 'support@radiofrigo.fr',
    },
    analytics: {
      matomo: '51',
    },
    useShell: [
      'bb-default',
      {
        mode: 'list',
      },
    ],
    nuxt: {
      head: {
        meta: [
          {
            name: 'robots',
            content:
              'noindex',
          },
        ],
      },
    },
    WFeedBack: {
      firstFeedback: {
        title: "Laisser un message à l'équipe Radio Frigo",
        name: 'Idée sujet',
        type: 'form',
        headCaption: `Vous souhaitez laisser un commentaire sur un épisode ou partager une idée pour un prochain sujet ?`,
        subCaption: `Je suis informé que Axima traite les données recueillies pour
        permettre la diffusion de votre dédicace. Pour en savoir plus,
        reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `Merci pour pour votre message !`,
        pseudonym: {
          label: 'Nom & prénom',
          placeholder: 'Martin Dupont',
          required: true,
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'martindupont@hotmail.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: 'Message',
            placeholder: 'votre message',
            value: '',
            type: 'text-field',
            required: true,
          },
          {
            label: 'J’accepte l’utilisation de mes données personnelles',
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
      secondFeedback: {
        title: 'Enregistre ton audio',
        name: 'enregistrement de la dédicace',
        type: 'audio',
        maxDuration: 30,
        headCaption: `Prend ta plus belle voix et laisse ton message audio`,
        subCaption: `Je suis informé que Axima traite les données recueillies pour
        permettre la diffusion de votre dédicace. Pour en savoir plus,
        reportez-vous à notre <a href="/data/personal-data">Politique de données personnelles.</a>`,
        formSentMessage: `On a bien enregistré ton audio, tu passeras à l'antenne`,
        pseudonym: {
          label: 'Nom & prénom',
          placeholder: 'Martin Dupont',
          required: true,
        },
        recorderForm: [
          {
            label: 'Adresse mail',
            placeholder: 'martindupont@hotmail.fr',
            value: '',
            type: 'email',
            required: true,
          },
          {
            label: "J'accepte que mon message soit diffusé sur Radio Frigo",
            value: false,
            type: 'checkbox',
            required: true,
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
